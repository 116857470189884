<template>
  <b-container
    style="min-height: 1250px"
    class="wall pb-5 fontStandard"
    fluid
    id="draggable-area"
  >
    <DraggableContainer
      :container="1500"
      :move="canMoveContainer"
      area="draggable-area"
      :class="['slideFromRight']"
      :left="300"
      :top="100"
      :OffscreenX="15"
      :OffscreenY="75"
      :spacer="25"
    >
      <div class="window welcome" :style="commonStyle">
        <div
          class="title text-left pl-2"
          @mouseover="canMoveContainer = true"
          @mouseleave="canMoveContainer = false"
        >
          Bienvenue
          <span class="position-absolute close" @click="close(1500)"
            ><i class="fas fa-times"></i
          ></span>
        </div>
        <div class="body text-center my-2 px-2 fontStandard">
          <strong
            >Script de déplacement de conteneur réalisé par moi-même avec
            Vue.js, Javascript et l'API HTML5 pour Ordinateur de bureau et
            portable uniquement.
          </strong>
          <h5 class="text-left pt-3">Ici j'ai utilisé principalement:</h5>
          <ol class="text-left">
            <li>
              <strong>getElementById:</strong> Pour récupérer les éléments HTML
              afin de pouvoir modifier leurs valeurs, principalement sur les
              feuilles de style mais également pour connaître et modifier leurs
              positions
            </li>
            <li>
              <strong>requestAnimationFrame:</strong> Pour créer une animation,
              concrètement cette fonction va permettre d’exécuter en boucle une
              <strong>method</strong> à une vitesse de 60 fois par seconde
              parfait pour le déplacement lissé des conteneurs
            </li>
            <li>
              <strong>addEventListener:</strong> Pour créer des écouteurs
              d'événements natif, comme
              <ul>
                <li><strong>mousedown</strong> clic maintenu</li>
                <li><strong>mousemove</strong> positionnement de la souris</li>
                <li><strong>mouseup</strong> clic relaché</li>
                <li>
                  <strong>resize</strong> détecter le format de la fenêtre
                </li>
              </ul>
            </li>
            <li>
              <strong>Brique logiciel Vue.js</strong> Chaque conteneur est un
              composant instancié, totalement personnalisable par les
              <code>props</code> et <code>slot</code> Vue, on peut en rajouter
              bien entendu, on peut l'améliorer à souhait
            </li>
            <li>
              <strong
                >#Vue <code>vm.$root.$emit</code> et
                <code>vm.$root.$on('event', handle)</code></strong
              >
              Pour les besoins du projet, j'ai crée des écouteurs personnalisés
              avec VueJS, ainsi lorsque on souhaite fermer un conteneur, nous le
              faisons via un événement VueJS. J'ai également fait en sorte que
              mon evenement soit global pour pouvoir être écouté en rajoutant
              <code>$root</code>
            </li>
          </ol>

          <p class="text-left">
            Bien entendu, il y a toute une partie de vérification de
            positionnement, de calcul anti débordement, de vérification, il
            n'est pas simple de tout détailler ici.
          </p>

          <p class="text-left">
            à vous de jouer, vous pouvez déplacer, rajouter des conteneurs,
            fermer et grâce à une transition le conteneur revient dans sa pile,
            pareil en cas débordement
          </p>

          <b-row fluid>
            <b-col cols="6">
              <b-button variant="info" block @click="addContainer()"
                >Ajouter un conteneur</b-button
              ></b-col
            >
            <b-col cols="6">
              <b-button variant="danger" block @click="delContainer()"
                >Supprimer un conteneur</b-button
              ></b-col
            >
          </b-row>
        </div>
      </div>
    </DraggableContainer>

    <!-- Code Exemple -->
    <DraggableContainer
      v-if="!isMobileBrowser"
      :container="1200"
      :move="canMoveContainer"
      area="draggable-area"
      :class="['slideFromRight']"
      :left="1050"
      :top="360"
      :OffscreenX="15"
      :OffscreenY="75"
      :spacer="25"
      :stack="false"
    >
      <!-- Au format mobile/tablette, 
        le déplacement de conteneur est désactivé, 
        alors j'ai crée une feuille de style réactive 
        Vue `commonStyle` pour forcer l'aspect onePage 
        dimensionné en largeur à 100% -->
      <div class="window exemple" :style="commonStyle">
        <div
          class="title text-left pl-2"
          @mouseover="canMoveContainer = true"
          @mouseleave="canMoveContainer = false"
        >
          Code Exemple
          <span class="position-absolute close" @click="close(1200)"
            ><i class="fas fa-times"></i
          ></span>
        </div>
        <div class="body">
          <b-img-lazy
            :src="pictures.dragCodeExample"
            alt="Code exemple d'un conteneur"
            class="exemple_img"
          />
        </div>
      </div>
    </DraggableContainer>
    <!-- Code Exemple -->

    <DraggableContainer
      :container="i"
      :move="canMoveContainer"
      area="draggable-area"
      v-for="(v, i) in Windows"
      :key="i"
      :class="[i % 2 === 0 ? 'slideFromRight' : 'slideFromLeft']"
      :left="PositionsX[i]"
      :top="PositionsY[i]"
      :OffscreenX="15"
      :OffscreenY="75"
      :spacer="25"
      :stack="i >= 3"
    >
      <div class="window px200" :style="commonStyle">
        <div
          class="title text-left pl-2"
          @mouseover="canMoveContainer = true"
          @mouseleave="canMoveContainer = false"
        >
          {{ v }}
          <span class="position-absolute close" @click="close(i)"
            ><i class="fas fa-times"></i
          ></span>
        </div>
        <div class="body">
          <QrCode
            :value="commons.qrcode.cv"
            color="black"
            background="transparent"
            className="mt-1 text-center"
            :size="160"
            v-if="i % 2 === 0"
          />

          <marquee
            v-else
            direction="down"
            width="200"
            height="180"
            behavior="alternate"
          >
            <marquee behavior="alternate"><p>Conteneur déplacable</p> </marquee>
          </marquee>
        </div>
      </div>
    </DraggableContainer>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    DraggableContainer: () => import("@/components/DraggableContainer"),
    QrCode: () => import("@/components/QrCode"),
  },
  computed: {
    ...mapState(["commons"]),
    ...mapGetters(["events/handleMinHeight"]),
    commonStyle() {
      return this.isMobileBrowser
        ? {
            width: "100%",
            height: "auto",
            top: 0,
            left: 0,
          }
        : {};
    },
    isMobileBrowser() {
      const isMobileBrowser = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ].some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
      return isMobileBrowser || false;
    },
    pictures() {
      if (this.$tools.canUseWebP()) {
        return {
          dragCodeExample: require("@/assets/webp/DraggableContainer.webp"),
        };
      } else {
        return {
          dragCodeExample: require("@/assets/img/DraggableContainer.jpg"),
        };
      }
    },
  },
  data() {
    return {
      canMoveContainer: false,
      Windows: [],
      PositionsX: [],
      PositionsY: [],
      draggableContainerReload: false,
    };
  },
  mounted() {
    if (!this.isMobileBrowser) {
      for (let i = 0; i < 25; i++) {
        setTimeout(() => {
          this.addContainer();
        }, 450 * i + 1);
      }
    }
  },
  methods: {
    addContainer() {
      if (this.Windows.length >= 3) {
        this.Windows.push("Random " + this.$tools.getRandomStr(8));
      } else {
        this.Windows.push("No stack " + parseInt(this.Windows.length + 1));
      }

      if (this.Windows.length > 3) {
        this.PositionsX.push(
          window.innerWidth / 3 +
            this.$tools.getRandomInt(window.innerWidth / 3)
        );
        this.PositionsY.push(
          window.innerHeight / 3 +
            this.$tools.getRandomInt(window.innerHeight / 3)
        );
      } else {
        if (this.Windows.length === 1) {
          this.PositionsX.push(1050);
          this.PositionsY.push(100);
        } else if (this.Windows.length === 2) {
          this.PositionsX.push(1275);
          this.PositionsY.push(100);
        } else {
          this.PositionsX.push(1500);
          this.PositionsY.push(100);
        }
      }
    },
    delContainer() {
      const instance = this.Windows.length - 1;
      this.Windows.pop();
      this.PositionsX.pop();
      this.PositionsY.pop();
      localStorage.removeItem(`cpn-dc-container-name-${instance}`);
    },
    close(instance) {
      if (
        this.$root.listToStack &&
        typeof this.$root.listToStack === "object" &&
        this.$root.listToStack.indexOf(instance) === -1
      ) {
        this.$root.listToStack.push(instance);
      }
    },
  },
};
</script>

<style scoped>
@keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(1000%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-1000%);
  }
  100% {
    transform: translateX(0%);
  }
}
.slideFromLeft {
  animation-name: slideFromLeft;
  animation-duration: 1.2s;
}
.slideFromRight {
  animation-name: slideFromRight;
  animation-duration: 1.2s;
}

.window {
  background: white;
  color: black;
  border: 1px solid black;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 5px 5px 5px black;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.px200 {
  width: 200px;
  height: 200px;
}
.welcome {
  width: 700px;
  height: auto;
}
.exemple {
  /* width: 450px; */
  height: auto;
  background: #1e1e1e;
}
.exemple_img {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.window .title {
  background: #333;
  color: white;
  height: 20px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 25px;
}
.window .body {
  color: black;
  width: 100%;
  height: auto;
}
.window .title .close {
  right: 5px;
  cursor: pointer;
  color: red;
  transition: 0.5s;
  opacity: 1;
  transform: scale(0.75);
  box-shadow: none;
}
</style>